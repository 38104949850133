.prefixButton {
    margin: 0 0.25rem 0;
}

.prismicContent img {
    width: auto!;
    height: auto!;
    max-width: 100%;
    max-height: 400px;
}

.prismicContent iframe {
    width: 100%;
    height: 400px;
}